import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import styled, { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"
import GatsbyImage from "gatsby-image"

import { Footer, Header } from "../components"

import { ImageCloud } from "../components/image-cloud/image-cloud"
import { Quote } from "../elements"
import { BodyText, Display, Highlight, Lead, Title } from "../identity"
import { Blue } from "../identity/typography/blue"
import { List } from "../identity/typography/list"

import ManiaProcess from "../images/mania/mania-process.svg"
import ManiaFacts from "../images/mania/mania-facts-figures.svg"
import { Container } from "../layout"
import { theme } from "../theme"

export default () => {
  const {
    one: {
      childImageSharp: { fixed: one },
    },
    two: {
      childImageSharp: { fixed: two },
    },
    three: {
      childImageSharp: { fixed: three },
    },
    four: {
      childImageSharp: { fixed: four },
    },
    five: {
      childImageSharp: { fixed: five },
    },
    six: {
      childImageSharp: { fixed: six },
    },
    philipp: {
      childImageSharp: { fixed: philipp },
    },
    mockup: {
      childImageSharp: { fluid: mockup },
    },
  } = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "mania/one.png" }) {
        childImageSharp {
          fixed(width: 468, height: 313) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      two: file(relativePath: { eq: "migipedia/05_couch_010.jpg" }) {
        childImageSharp {
          fixed(width: 579, height: 387) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      three: file(relativePath: { eq: "mania/three.png" }) {
        childImageSharp {
          fixed(width: 468, height: 313) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      four: file(relativePath: { eq: "mania/four.png" }) {
        childImageSharp {
          fixed(width: 468, height: 313) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      five: file(relativePath: { eq: "mania/five.png" }) {
        childImageSharp {
          fixed(width: 579, height: 387) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      six: file(relativePath: { eq: "mania/six.png" }) {
        childImageSharp {
          fixed(width: 468, height: 313) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      philipp: file(relativePath: { eq: "migipedia/philipp-buehler.jpg" }) {
        childImageSharp {
          fixed(width: 400, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mockup: file(relativePath: { eq: "mania/mockup.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <title>mutabor | smartive — Eingabe Best of Swiss Web 2021</title>
        </Helmet>
        <Normalize />
        <Header
          heading="Eingabe Best of Swiss Web 2021"
          subheading="Kategorien Marketing und Business"
          withMutaborAsPartner
        />
        <Container>
          <Display>
            <Highlight>Die Tauschbörse:</Highlight> Migros macht das Tauschen
            digital
          </Display>
          <Lead>
            Eine neue Plattform, die das Tauschen mit Hilfe smarter Features
            einfacher macht, Menschen verbindet und Kundinnen und Kunden in die
            digitalen Kanäle der Migros überführt. Die Migros Manias zählen zu
            den beliebtesten Promotionen der Migros. Für jeden Einkauf über
            einen bestimmten Betrag bekommen Kundinnen und Kunden
            Mania-Sammelelemente, die sie sammeln und untereinander tauschen
            können, um am Ende alle Sammelelemente zu besitzen. Eine
            Win-win-Situation, die Kundinnen und Kunden Spass macht, den
            durchschnittlichen Warenkorb und die Frequenz im Laden steigert.
            Zumindest in Nicht-Corona-Zeiten sind die beiden umsatztreibende
            Erfolgsfaktoren im Detailhandel.
          </Lead>
        </Container>
        <Container>
          <ManiaProcess />
        </Container>
        <Container>
          <Title number="01">Die Herausforderung</Title>
          <BodyText>
            Kundinnen und Kunden, die mit viel Engagement und Freude an den
            Promotionen teilnehmen, konnten nach dem Erhalt der Sammelelemente
            kaum mehr mit Migros-Botschaften erreicht werden. Auch beim Tauschen
            der Sammelelemente waren sie auf sich allein gestellt. Darum
            organisierten sich Kundinnen und Kunden bis dato selbst:
          </BodyText>
          <BodyText>
            <List listType="unordered">
              <BodyText>
                Viele kleine, individuelle Tauschgruppen mit Nachbarn, Freunden
                und Familien
              </BodyText>
              <BodyText>
                Tauschen und Verkaufen von Sammelelementen auf digitalen
                Plattformen wie Facebook, Tutti oder Ricardo
              </BodyText>
            </List>
          </BodyText>
          <div>
            <BodyText>
              In der Vergangenheit organisierte die Migros teilweise
              Tauschevents in grösseren Einkaufszentren. Davon konnten aber
              nicht alle Kundinnen und Kunden profitieren und auf Grund von
              Corona hatte man entschieden, die organisierten Tauschbörsen nicht
              anzubieten. Eine zentrale Anlaufstelle gab es somit 2020 nicht.
              Aber weil die Migros dazu beitragen möchte, «täglich besser zu
              leben», digitalisierte sie das Thema Tauschen. Dabei führte die
              Migros Gleichgesinnte zusammen und nutzte zum einen die Reichweite
              der eigenen Kanäle und zum anderen das Vertrauen in die Marke, um
              Kundinnen und Kunden auch nach dem Einkauf in den (digitalen)
              Kanälen der Migros zu behalten.
            </BodyText>
          </div>
        </Container>

        <ImageCloud images={[one, two, three, four, five, six]} />
        <Container>
          <Title number="02">Die Lösung: Eine digitale Tauschbörse</Title>
          <BodyText>
            Gemeinsam mit der Migros entwickelten Mutabor und smartive eine
            digitale Tauschbörse auf der Community-Plattform Migipedia. Ein
            zentraler Ort für alle Tauschangebote mit smarten Features, die das
            Vervollständigen der eigenen Mania-Sammlung erleichtern.
          </BodyText>
          <BodyText>
            <List listType="unordered">
              <BodyText>
                Kundinnen und Kunden können Sammelelemente wie Tiptoi Sticker
                oder All Stars Murmeln in ein digitales Sammelalbum eintragen.
                Sie sehen direkt, wie viele Elemente bis zur vollständigen
                Sammlung fehlen und welche Elemente mehrfach vorhanden sind.
              </BodyText>
              <BodyText>
                Eine vollständige Sammlung kann durch Tauschen erreicht werden.
                Ein Algorithmus berechnet auf Basis der eigenen
                Mania-Sammelelemente die potentiell besten Tauschpartner. Diese
                werden in einer übersichtlichen Liste dargestellt.
              </BodyText>
              <BodyText>
                Über eine Chatfunktion können Kundinnen und Kunden anonymisiert
                miteinander kommunizieren und die Details eines Tausches
                aushandeln. Ganz einfach.
              </BodyText>
              <BodyText>
                Dank dem oben genannten Best-Match-Algorithmus ist es möglich,
                mit nur wenigen Tauschaktionen die eigene Sammlung rasch zu
                komplettieren.
              </BodyText>
            </List>
          </BodyText>
        </Container>
        <Container>
          <GatsbyImage fluid={mockup} />
        </Container>

        <Quote
          author="Philipp Bühler, Senior Product Owner Migipedia & Brand"
          photo={philipp}
        >
          &laquo;Von 0 auf 100. Begleitend zu zwei Manias ist es uns 2020
          gelungen, gleich die grösste Tauschbörse für Kundinnen und Kunden
          anzubieten. Und dies auf unserem eigenen digitalen Kanal. Das
          Vertrauen in die Marke Migros hat dabei eine entscheidende Rolle
          gespielt.&raquo;
        </Quote>
        <Container>
          <Title number="03">Die Migros Tauschbörse in Zahlen</Title>
          <BodyText>
            Mit der Lancierung der Mania-Tauschbörse hat die Migros ein neues,
            hoch involvierendes Community-Feature geschaffen, das für Kundinnen
            und Kunden auch über den Einkauf hinaus Relevanz besitzt.
          </BodyText>
        </Container>
        <Container>
          <ManiaFacts />
        </Container>
        <Container>
          <BodyText>
            Filialen kennen Öffnungszeiten. Manias besitzen
            Kampagnen-Laufzeiten. Die Tauschbörse allerdings nicht. Noch heute
            können Elemente der Tiptoi- und der Migros All Stars Mania getauscht
            werden. Und schon bald folgt die nächste Mania mit ihren
            Sammelelementen.
          </BodyText>
        </Container>

        <Container>
          <iframe
            src="https://player.vimeo.com/video/512524302"
            width="758"
            height="426"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Container>
        <Container>
          <Title>Jury-Hinweis</Title>
          <BodyText>
            Ein Account mit Benutzernamen und Passwort und ist in der
            BOSW-Einreichung in den juryrelevanten Informationen im Bereich
            Zugangsdaten hinterlegt.
          </BodyText>
          <BodyText>
            Allfällige Bewertungen und weitere Community-Interaktionen finden in
            der Live-Umgebung statt und sind für alle Kundinnen und Kunden
            sichtbar.
          </BodyText>
        </Container>
        <Footer withMutaborAsPartner />
      </>
    </ThemeProvider>
  )
}

const Bold = styled.div`
  font-weight: 600;
`
